export const dbEastUS = {
  host: 'recovib-prod-eastus-smoky-db',
  db: 'recovib-prod-eastus-smoky-db',
  mastKey: 'KTN6Cr7uF5daDUTIOqUCMD2t9eG4m6rmsf4QcO5aAAUsmdmgtvDqWjdq3FSn5zcyLbJH63cpiXLO126gltLZWQ==',
  collection: 'production'
}

export const dbWeuNinja = {
  host: '',
  db: '',
  mastKey: '',
  collection: ''
}

export const dbSeasiaFable = {
  host: '',
  db: '',
  mastKey: '',
  collection: ''
}

export const dbMain = {
  host: '',
  db: '',
  mastKey: '',
  collection: ''
}

export const dbStaging = {
  host: '',
  db: '',
  mastKey: '',
  collection: ''
}

export const prodPr202 = {
  host: '',
  db: '',
  mastKey: '',
  collection: ''
}

export const prodDb212 = {
  host: '', // FIXME ?
  db: '',
  mastKey: '',
  collection: '' // FIXME ?
}
