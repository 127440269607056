export const table = [
  {
    "slug": "micromega",
    "i18n": "Micromega",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "accounts": [
      "rec4L58te2VjL3aX1",
      "rechTfkxQZ5CFWPak",
      "rec1NUB2Pul5jJvou",
      "recU7Na7qndvBDpEq",
      "recD2EAEwb6MhJEtz",
      "recOTPZbECMahKTAz",
      "recmdJRvcoD0kbHOB",
      "recfUfFZAohqo5jIV"
    ],
    "_id": "recvoQP8sJKPPs2My"
  },
  {
    "slug": "modyva",
    "i18n": "Modyva",
    "roles": [
      "recuMI23vp24Nem1E"
    ],
    "accounts": [
      "reczf1PhQxbqFkVi5",
      "recOF6J4OlWNy7GDq",
      "recSFOMDKLj2oJzV5",
      "recQ34ASV3lwqzNSl",
      "recrjvchXZbJJcfLN"
    ],
    "_id": "rec3rIIgX7EhOMGZe"
  },
  {
    "slug": "lhoist",
    "i18n": "Lhoist",
    "roles": [
      "recGBgCulF5wURaEj",
      "rec3zT8Bx72ta947J",
      "recvCS8g5GSJa9orC",
      "recBlfM12QfKIh5Ps"
    ],
    "_id": "recnCSHGEhCouNEG8"
  },
  {
    "slug": "galere",
    "i18n": "Galere",
    "roles": [
      "recrZzZTH4wZhXAFT"
    ],
    "_id": "recJ6RboFEA58XeaU"
  },
  {
    "slug": "thomas-piron",
    "i18n": "Thomas Piron",
    "roles": [
      "recJphSCMBDY1WRXB"
    ],
    "_id": "reckUK3s7qJtI2Ihu"
  },
  {
    "slug": "euro-com-museum",
    "i18n": "European Commission Museum",
    "roles": [
      "recB4nLqfCdCCL3iC"
    ],
    "_id": "recGFDBtzdX7ZPadH"
  },
  {
    "slug": "edith-cavell-hospital",
    "i18n": "Edit Cavell Hospital",
    "roles": [
      "recZlUZaITAYeBpbu"
    ],
    "_id": "rec3rXu4ZSnB4CjAO"
  },
  {
    "slug": "walibi-holland",
    "i18n": "Wallib Holland",
    "roles": [
      "recmjqaVYPi28m478"
    ],
    "_id": "recWxXeYSH5fEkriU"
  },
  {
    "slug": "flow-engineering",
    "i18n": "Flow Engineering",
    "roles": [
      "rec8sJ93VeGZ4Xboa"
    ],
    "accounts": [
      "recT2vVyw3ad0WrwV",
      "rec4SG1ItfDoPEVCe"
    ],
    "_id": "recFdorhR2L2PyGlt"
  },
  {
    "slug": "flagpole",
    "i18n": "Flagpole",
    "roles": [
      "rec2c9eI6cSOTpD0O"
    ],
    "_id": "rec4oC1GUXBPrWL6A"
  },
  {
    "slug": "gantrex",
    "i18n": "Gantrex",
    "roles": [
      "recDkaQZsl2y1B9zq",
      "recxwvTKO9spIcKtL"
    ],
    "accounts": [
      "recj3JPqm7bpHHJQz",
      "recafuoFGwifWLdA0",
      "recA65tYVI8HFwvYO",
      "rec1bgCaP4j9kR0Nl"
    ],
    "_id": "recU1pa2SJFSyWDWe"
  },
  {
    "slug": "melbourne-heaven",
    "i18n": "Melbourne Heaven",
    "roles": [
      "recRCitRFrJk7mfBN"
    ],
    "_id": "recbA0HWV2rhZziOK"
  },
  {
    "slug": "new-york-heaven",
    "i18n": "New York Heaven",
    "accounts": [
      "recafuoFGwifWLdA0",
      "rec1bgCaP4j9kR0Nl"
    ],
    "_id": "recD8vc8tsOg6stqv"
  },
  {
    "slug": "pommeroeul",
    "i18n": "Pommeroeul",
    "roles": [
      "rec8K4WDuRN2nyt3t"
    ],
    "accounts": [
      "recD2EAEwb6MhJEtz"
    ],
    "_id": "recmwETD9fuoACWfQ"
  },
  {
    "slug": "demo",
    "i18n": "Demo",
    "roles": [
      "recxoKq30CWOCv0MI"
    ],
    "accounts": [
      "recOTPZbECMahKTAz",
      "recfUfFZAohqo5jIV"
    ],
    "_id": "recdj2DJRFdt2KlPz"
  },
  {
    "slug": "cementys",
    "i18n": "Cementys",
    "roles": [
      "recNX6RzCcOUvfTiW"
    ],
    "accounts": [
      "recC9PrkPLM7Ngxr9"
    ],
    "_id": "rec1fy7KxDt61dtsM"
  },
  {
    "slug": "vmvtech",
    "i18n": "VMVTech corporation",
    "roles": [
      "reclIvVgg5lseFWb5"
    ],
    "accounts": [
      "recLQq6bzpji0kgqa",
      "recn2m6fU3RYHUijA"
    ],
    "_id": "reciVfBcFT4oF3TnA"
  },
  {
    "slug": "offtech",
    "i18n": "Offtech Pumacy",
    "roles": [
      "recbkJivrEUkX2mvS"
    ],
    "accounts": [
      "recBxCcwub9NS2IvT"
    ],
    "_id": "recRMyJpPaASjQAA1"
  },
  {
    "slug": "transcendence",
    "i18n": "Transcendence",
    "roles": [
      "rec3oZPZmACrpvWGn"
    ],
    "accounts": [
      "recqQeSjl4XFHkoLN"
    ],
    "_id": "recAfGj3Mxjh9AjtB"
  },
  {
    "_id": "recdkHzop63mHtrGP"
  }
]