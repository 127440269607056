export const table = [
  {
    "slug": "lhoist-quarry-marchelesdames",
    "location_i18n": "Marche-les-Dames",
    "roles": [
      "recGBgCulF5wURaEj",
      "rec3zT8Bx72ta947J",
      "recuMI23vp24Nem1E",
      "recvCS8g5GSJa9orC",
      "recBlfM12QfKIh5Ps"
    ],
    "i18n": "Lhoist Quarry",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "rec3rIIgX7EhOMGZe"
    ],
    "lenses": [
      "recJMXontAqJzsg8H",
      "rechsvgMd5jSeJXMs",
      "recp4ROAAbvYxd7LI"
    ],
    "status": "active",
    "_id": "reczf1PhQxbqFkVi5"
  },
  {
    "slug": "bierset-ground-radar",
    "location_i18n": "Bierset - Galère Construction Work",
    "roles": [
      "recrZzZTH4wZhXAFT",
      "recuMI23vp24Nem1E"
    ],
    "i18n": "Airport Ground Radar",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "rec3rIIgX7EhOMGZe"
    ],
    "lenses": [
      "recYc2iarob6sf1t2"
    ],
    "status": "closed",
    "_id": "recOF6J4OlWNy7GDq"
  },
  {
    "slug": "thomas-piron-mons",
    "location_i18n": "Mons",
    "roles": [
      "recJphSCMBDY1WRXB",
      "recuMI23vp24Nem1E"
    ],
    "i18n": "Thomas-Piron Construction Work",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "rec3rIIgX7EhOMGZe"
    ],
    "lenses": [
      "rec2cKlJnOyliRULH"
    ],
    "status": "closed",
    "_id": "recSFOMDKLj2oJzV5"
  },
  {
    "slug": "ec-museum",
    "location_i18n": "Brussels",
    "roles": [
      "recB4nLqfCdCCL3iC",
      "recuMI23vp24Nem1E"
    ],
    "i18n": "EC Museum",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "rec3rIIgX7EhOMGZe"
    ],
    "lenses": [
      "rec0A09Yy6APkH8IX"
    ],
    "status": "closed",
    "_id": "recQ34ASV3lwqzNSl"
  },
  {
    "slug": "edith-cavel-hospital",
    "location_i18n": "Brussels",
    "roles": [
      "recZlUZaITAYeBpbu",
      "recuMI23vp24Nem1E"
    ],
    "i18n": "Edith Cavel Hospital",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "rec3rIIgX7EhOMGZe"
    ],
    "lenses": [
      "recBQrLnA2q2DkABp"
    ],
    "status": "canceled",
    "_id": "recrjvchXZbJJcfLN"
  },
  {
    "slug": "walibi-nl-roller-coaster",
    "location_i18n": "Biddinghuizen",
    "roles": [
      "recmjqaVYPi28m478",
      "rec8sJ93VeGZ4Xboa"
    ],
    "i18n": "Walibi Holland Roller Coaster",
    "country_i18n": "Netherlands",
    "affiliate-manager": [
      "recFdorhR2L2PyGlt"
    ],
    "lenses": [
      "rec3zruE96pwU4Mmx"
    ],
    "status": "re-start-pending ",
    "_id": "recT2vVyw3ad0WrwV"
  },
  {
    "slug": "flagpole",
    "location_i18n": "Sheboygan Winsconsin",
    "roles": [
      "rec2c9eI6cSOTpD0O",
      "rec8sJ93VeGZ4Xboa"
    ],
    "i18n": "Flagpole",
    "country_i18n": "USA",
    "affiliate-manager": [
      "recFdorhR2L2PyGlt"
    ],
    "lenses": [
      "recepCAO6iSHJMgsQ"
    ],
    "status": "proposed",
    "_id": "rec4SG1ItfDoPEVCe"
  },
  {
    "slug": "melb-heaven-rails",
    "location_i18n": "Melbourne",
    "roles": [
      "recRCitRFrJk7mfBN",
      "recDkaQZsl2y1B9zq"
    ],
    "i18n": "Port of Melbourne – VICT – ASC Blocks",
    "country_i18n": "Australia",
    "affiliate-manager": [
      "recU1pa2SJFSyWDWe"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "proposed",
    "_id": "recj3JPqm7bpHHJQz"
  },
  {
    "slug": "ny-heaven-rails",
    "location_i18n": "New York",
    "roles": [
      "recxwvTKO9spIcKtL",
      "recC29wEmwU4vjOoQ",
      "recDkaQZsl2y1B9zq"
    ],
    "i18n": "Port of New York – Maher Hinge Guard crane #40",
    "country_i18n": "USA",
    "affiliate-manager": [
      "recU1pa2SJFSyWDWe",
      "recD8vc8tsOg6stqv"
    ],
    "lenses": [
      "recIZvFrLqOzmiidA",
      "recv1HUNAKeTgPjp7"
    ],
    "status": "active",
    "_id": "recafuoFGwifWLdA0"
  },
  {
    "slug": "ny-heaven-rails-staging",
    "location_i18n": "New York",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "[STAGING] Crane rails - New York Heaven",
    "country_i18n": "USA",
    "affiliate-manager": [
      "recU1pa2SJFSyWDWe",
      "recD8vc8tsOg6stqv"
    ],
    "lenses": [
      "recb0iGXNF4ttgsqy",
      "recHe0EGSjf5E5Pdi"
    ],
    "status": "active",
    "_id": "rec1bgCaP4j9kR0Nl"
  },
  {
    "slug": "hv-heaven-rails",
    "location_i18n": "Le Havre",
    "roles": [
      "recxwvTKO9spIcKtL",
      "recC29wEmwU4vjOoQ",
      "recDkaQZsl2y1B9zq"
    ],
    "i18n": "Le Havre – TPO – Hinge Guard grue #xx",
    "country_i18n": "France",
    "affiliate-manager": [
      "recU1pa2SJFSyWDWe"
    ],
    "lenses": [
      "rec3TgAKbl8FfV3kM"
    ],
    "status": "proposed",
    "_id": "recA65tYVI8HFwvYO"
  },
  {
    "slug": "gsm-pylon-structural-integrity",
    "location_i18n": "Canada",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "GSM pylons",
    "country_i18n": "Canada",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My"
    ],
    "lenses": [
      "recei1HzR5aCFbvND"
    ],
    "status": "proposed",
    "_id": "rec4L58te2VjL3aX1"
  },
  {
    "slug": "wind-turbine",
    "location_i18n": "South Africa",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "30 MW Wind turbines",
    "country_i18n": "South Africa",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My"
    ],
    "lenses": [
      "recrqbTDt2kVz4a0W"
    ],
    "status": "proposed",
    "_id": "rechTfkxQZ5CFWPak"
  },
  {
    "slug": "cooling-system",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Cooling System Monitoring",
    "country_i18n": "South Korea",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My"
    ],
    "lenses": [
      "recbkQa8sxAodRQe6"
    ],
    "status": "proposed",
    "_id": "rec1NUB2Pul5jJvou"
  },
  {
    "slug": "mumbai-pagoda",
    "location_i18n": "Mumbai",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Global Vipassana Pagoda",
    "country_i18n": "India",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "proposed",
    "_id": "recU7Na7qndvBDpEq"
  },
  {
    "slug": "pommeroeul-railways",
    "location_i18n": "Ville Pommeroeul",
    "roles": [
      "rec8K4WDuRN2nyt3t",
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Railways Vibration",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My",
      "recmwETD9fuoACWfQ"
    ],
    "lenses": [
      "recNzA9K9el5bRZjb"
    ],
    "status": "active",
    "_id": "recD2EAEwb6MhJEtz"
  },
  {
    "slug": "demo_mode",
    "location_i18n": "Fernelmont",
    "roles": [
      "recxoKq30CWOCv0MI",
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Demo Account",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My",
      "recdj2DJRFdt2KlPz"
    ],
    "lenses": [
      "recgjNz3HAVWQol3G",
      "recDkwA4Ue0InS7aW"
    ],
    "status": "active",
    "_id": "recOTPZbECMahKTAz"
  },
  {
    "slug": "staging",
    "location_i18n": "Fernelmont",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Staging Account",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My",
      "recdj2DJRFdt2KlPz"
    ],
    "status": "active",
    "_id": "recfUfFZAohqo5jIV"
  },
  {
    "slug": "cementys-1st-account",
    "location_i18n": "Underwater",
    "roles": [
      "recNX6RzCcOUvfTiW"
    ],
    "i18n": "Cementys Account #1",
    "country_i18n": "France",
    "affiliate-manager": [
      "rec1fy7KxDt61dtsM"
    ],
    "lenses": [
      "recsHz6kCv6YROaxd"
    ],
    "status": "active",
    "_id": "recC9PrkPLM7Ngxr9"
  },
  {
    "slug": "vmv-1st-account",
    "location_i18n": "Pohang",
    "roles": [
      "reclIvVgg5lseFWb5"
    ],
    "i18n": "POSCO",
    "country_i18n": "South Korea",
    "affiliate-manager": [
      "reciVfBcFT4oF3TnA"
    ],
    "lenses": [
      "recMKc4UX6KSh95wA"
    ],
    "status": "active",
    "_id": "recLQq6bzpji0kgqa"
  },
  {
    "slug": "travelec",
    "location_i18n": "Brussels",
    "roles": [
      "recC29wEmwU4vjOoQ"
    ],
    "i18n": "Travelec Staging Experiments",
    "country_i18n": "Belgium",
    "affiliate-manager": [
      "recvoQP8sJKPPs2My"
    ],
    "lenses": [
      "recpUl6IA69vz2tFh"
    ],
    "status": "active",
    "_id": "recmdJRvcoD0kbHOB"
  },
  {
    "slug": "vmv-closed-account",
    "location_i18n": "Seoul",
    "roles": [
      "reclIvVgg5lseFWb5"
    ],
    "i18n": "Airport Surveillance Radar",
    "country_i18n": "South Korea",
    "affiliate-manager": [
      "reciVfBcFT4oF3TnA"
    ],
    "lenses": [
      "recgk3TzUss2xCX7U"
    ],
    "status": "closed",
    "_id": "recn2m6fU3RYHUijA"
  },
  {
    "slug": "offtech-temperature",
    "location_i18n": "Berlin",
    "roles": [
      "recbkJivrEUkX2mvS"
    ],
    "i18n": "Offtech Temperature Recording (Pumacy)",
    "country_i18n": "Germany",
    "affiliate-manager": [
      "recRMyJpPaASjQAA1"
    ],
    "lenses": [
      "recX6OkxfrCqldLhP"
    ],
    "status": "active",
    "_id": "recBxCcwub9NS2IvT"
  },
  {
    "slug": "transcendence",
    "location_i18n": "DC/2018/05",
    "roles": [
      "rec3oZPZmACrpvWGn"
    ],
    "i18n": "Contract",
    "country_i18n": "Hong Kong",
    "affiliate-manager": [
      "recAfGj3Mxjh9AjtB"
    ],
    "lenses": [
      "recBDu3XnWSufJpT7",
      "recJnlDm6hayL2X4o",
      "recE6B2uUt8VKHjbu",
      "recpphl6SiHo4lAny",
      "recHDSfE4RPKaINFh"
    ],
    "status": "active",
    "_id": "recqQeSjl4XFHkoLN"
  },
  {
    "_id": "recpbE4aNd47yjqDh"
  },
  {
    "_id": "rec6c5JwcF4WNvr7x"
  },
  {
    "_id": "recKqCShDX1WweIa8"
  }
]