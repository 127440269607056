export const table = [
  {
    "role_type": "root",
    "accounts": [
      "recD2EAEwb6MhJEtz",
      "recU7Na7qndvBDpEq",
      "rec1NUB2Pul5jJvou",
      "rechTfkxQZ5CFWPak",
      "rec4L58te2VjL3aX1",
      "recafuoFGwifWLdA0",
      "recA65tYVI8HFwvYO",
      "recOTPZbECMahKTAz",
      "recmdJRvcoD0kbHOB",
      "recfUfFZAohqo5jIV",
      "rec1bgCaP4j9kR0Nl"
    ],
    "affiliation": [
      "recvoQP8sJKPPs2My"
    ],
    "permissions": [
      "crud-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recqsbtFFK86DMmMP"
    ],
    "slug": "micromega__root",
    "_id": "recC29wEmwU4vjOoQ"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "reczf1PhQxbqFkVi5",
      "recOF6J4OlWNy7GDq",
      "recSFOMDKLj2oJzV5",
      "recQ34ASV3lwqzNSl",
      "recrjvchXZbJJcfLN"
    ],
    "affiliation": [
      "rec3rIIgX7EhOMGZe"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recZ2FWx9Q8mqDswb"
    ],
    "slug": "modyva__affiliate-admin",
    "_id": "recuMI23vp24Nem1E"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "reczf1PhQxbqFkVi5"
    ],
    "affiliation": [
      "recnCSHGEhCouNEG8"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recugxU3pOkfe6Dbj"
    ],
    "slug": "lhoist__account-admin",
    "_id": "rec3zT8Bx72ta947J"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recOTPZbECMahKTAz"
    ],
    "affiliation": [
      "recdj2DJRFdt2KlPz"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recgQ64SAfmz259ag"
    ],
    "slug": "demo__account-admin",
    "_id": "recxoKq30CWOCv0MI"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recOF6J4OlWNy7GDq"
    ],
    "affiliation": [
      "recJ6RboFEA58XeaU"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "galere__account-admin",
    "_id": "recrZzZTH4wZhXAFT"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recSFOMDKLj2oJzV5"
    ],
    "affiliation": [
      "reckUK3s7qJtI2Ihu"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "thomas-piron__account-admin",
    "_id": "recJphSCMBDY1WRXB"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recQ34ASV3lwqzNSl"
    ],
    "affiliation": [
      "recGFDBtzdX7ZPadH"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "euro-com-museum__account-admin",
    "_id": "recB4nLqfCdCCL3iC"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recrjvchXZbJJcfLN"
    ],
    "affiliation": [
      "rec3rXu4ZSnB4CjAO"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "edith-cavell-hospital__account-admin",
    "_id": "recZlUZaITAYeBpbu"
  },
  {
    "role_type": "resident",
    "accounts": [
      "reczf1PhQxbqFkVi5"
    ],
    "affiliation": [
      "recnCSHGEhCouNEG8"
    ],
    "permissions": [
      "_r__-account",
      "crud-own"
    ],
    "slug": "lhoist__resident",
    "_id": "recGBgCulF5wURaEj"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recT2vVyw3ad0WrwV"
    ],
    "affiliation": [
      "recWxXeYSH5fEkriU"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "walibi-holland__account-admin",
    "_id": "recmjqaVYPi28m478"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recT2vVyw3ad0WrwV",
      "rec4SG1ItfDoPEVCe"
    ],
    "affiliation": [
      "recFdorhR2L2PyGlt"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recdIlnZTUYNGQTQr"
    ],
    "slug": "flow-engineering__affiliate-admin",
    "_id": "rec8sJ93VeGZ4Xboa"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "rec4SG1ItfDoPEVCe"
    ],
    "affiliation": [
      "rec4oC1GUXBPrWL6A"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "flagpole__account-admin",
    "_id": "rec2c9eI6cSOTpD0O"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recj3JPqm7bpHHJQz",
      "recafuoFGwifWLdA0",
      "recA65tYVI8HFwvYO"
    ],
    "affiliation": [
      "recU1pa2SJFSyWDWe"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recQdoOIJxjXIB8aM"
    ],
    "slug": "gantrex__affiliate-admin",
    "_id": "recDkaQZsl2y1B9zq"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recj3JPqm7bpHHJQz"
    ],
    "affiliation": [
      "recbA0HWV2rhZziOK"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "melbourne-heaven__account-admin",
    "_id": "recRCitRFrJk7mfBN"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recafuoFGwifWLdA0",
      "recA65tYVI8HFwvYO"
    ],
    "affiliation": [
      "recU1pa2SJFSyWDWe"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "slug": "gantrex__account-admin",
    "_id": "recxwvTKO9spIcKtL"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recD2EAEwb6MhJEtz"
    ],
    "affiliation": [
      "recmwETD9fuoACWfQ"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "recqsbtFFK86DMmMP",
      "recFgFgYkeIcOK8Ja"
    ],
    "slug": "pommeroeul__account-admin",
    "_id": "rec8K4WDuRN2nyt3t"
  },
  {
    "role_type": "alert_sms_recipient",
    "accounts": [
      "reczf1PhQxbqFkVi5"
    ],
    "affiliation": [
      "recnCSHGEhCouNEG8"
    ],
    "users": [
      "rec9MunPGwKcDFBxC"
    ],
    "slug": "lhoist__alert_sms_recipient",
    "_id": "recvCS8g5GSJa9orC"
  },
  {
    "role_type": "alert_email_recipient",
    "accounts": [
      "reczf1PhQxbqFkVi5"
    ],
    "affiliation": [
      "recnCSHGEhCouNEG8"
    ],
    "users": [
      "rec9MunPGwKcDFBxC"
    ],
    "slug": "lhoist__alert_email_recipient",
    "_id": "recBlfM12QfKIh5Ps"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recC9PrkPLM7Ngxr9"
    ],
    "affiliation": [
      "rec1fy7KxDt61dtsM"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "reczKx0EyJOWi1NAb"
    ],
    "slug": "cementys__affiliate-admin",
    "_id": "recNX6RzCcOUvfTiW"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recLQq6bzpji0kgqa",
      "recn2m6fU3RYHUijA"
    ],
    "affiliation": [
      "reciVfBcFT4oF3TnA"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recEdvOSwetzR5QHj"
    ],
    "slug": "vmvtech__affiliate-admin",
    "_id": "reclIvVgg5lseFWb5"
  },
  {
    "role_type": "account-admin",
    "accounts": [
      "recBxCcwub9NS2IvT"
    ],
    "affiliation": [
      "recRMyJpPaASjQAA1"
    ],
    "permissions": [
      "_ru__-account",
      "crud-own"
    ],
    "users": [
      "rec8gJpIfAJVWB6Lk"
    ],
    "slug": "offtech__account-admin",
    "_id": "recbkJivrEUkX2mvS"
  },
  {
    "role_type": "affiliate-admin",
    "accounts": [
      "recqQeSjl4XFHkoLN"
    ],
    "affiliation": [
      "recAfGj3Mxjh9AjtB"
    ],
    "permissions": [
      "_ru_-affiliate",
      "crud-account",
      "crud-own"
    ],
    "users": [
      "recbkYdWsXfJXYbTs"
    ],
    "slug": "transcendence__affiliate-admin",
    "_id": "rec3oZPZmACrpvWGn"
  },
  {
    "slug": "__",
    "_id": "recewUsLBCG0wyBnL"
  }
]