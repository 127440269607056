export const table = [
  {
    slug: 'demo',
    i18n: 'demo',
    role: ['recxoKq30CWOCv0MI'],
    pass: 'demo',
    status: 'active',
    _id: 'recgQ64SAfmz259ag'
  },
  {
    slug: 'gantrex',
    i18n: 'Gantrex',
    role: ['recDkaQZsl2y1B9zq'],
    pass: '$wiftLock47',
    status: 'active',
    _id: 'recQdoOIJxjXIB8aM'
  }
]
