export const authenticate = (user, pass) => {
  if (user === 'gantrex' && pass === '$wiftLock47') {
    user = 'gantrex'
  } else if (user === 'demo' && pass === 'demo') {
    user = 'demo'
  } else {
    // user = undefined
  }
  return user
}
