export const table = [
  {
    "i18n": "Building vibration - 81 rue Emile Vandevelde - Namêche",
    "data_streams": [
      "recIBDky5zeo675eG"
    ],
    "phase": "production",
    "lens_uid": "1-blasting-vib",
    "account": [
      "reczf1PhQxbqFkVi5"
    ],
    "resources": "recovib-io-modyva, pr202-azure-website",
    "lens_connectors": [
      "recHbr75IONoPIctr",
      "recksTYwFHNJAcPqg",
      "recu43lwYpX2WT5bp",
      "recdoHzILrmvxc7yN",
      "recVvQ0rmYRkcIGZB",
      "recu5g3sbcMgW4aSk",
      "recUZNuN8Aw7sTdvl",
      "receZKVU9apHKyhJz",
      "recyIicFg05dAFZMs"
    ],
    "slug": "lhoist-quarry-marchelesdames__1-blasting-vib",
    "status": "active",
    "_id": "rechsvgMd5jSeJXMs"
  },
  {
    "i18n": "Air pressure - Chateau de Melroy - Vezin",
    "data_streams": [
      "recj2lS80a8DGR9hB"
    ],
    "phase": "production",
    "lens_uid": "2-blasting-air",
    "account": [
      "reczf1PhQxbqFkVi5"
    ],
    "resources": "recovib-io-modyva, pr202-azure-website",
    "lens_connectors": [
      "recvaN9hrVcjoyPMj",
      "recscoYyaBJ9LnMLD"
    ],
    "slug": "lhoist-quarry-marchelesdames__2-blasting-air",
    "status": "active",
    "_id": "recJMXontAqJzsg8H"
  },
  {
    "i18n": "Dust - Marche-les-Dames",
    "data_streams": [
      "recioW4KWg4zPwOIF"
    ],
    "phase": "production",
    "lens_uid": "3-blasting-dust",
    "account": [
      "reczf1PhQxbqFkVi5"
    ],
    "resources": "recovib-io-modyva, pr202-azure-website",
    "lens_connectors": [
      "recvaN9hrVcjoyPMj",
      "recscoYyaBJ9LnMLD"
    ],
    "slug": "lhoist-quarry-marchelesdames__3-blasting-dust",
    "status": "active",
    "_id": "recp4ROAAbvYxd7LI"
  },
  {
    "i18n": "Air pressure - Demo",
    "data_streams": [
      "recOL4qWlaN3S6u0S"
    ],
    "phase": "production",
    "lens_uid": "2-demo-air",
    "account": [
      "recOTPZbECMahKTAz"
    ],
    "resources": "recovib-io-demo",
    "lens_connectors": [
      "recAoimMe99XzsrOO",
      "recHIl3dgsotygldB"
    ],
    "slug": "demo_mode__2-demo-air",
    "status": "active",
    "_id": "recDkwA4Ue0InS7aW"
  },
  {
    "i18n": "Building vibration - Demo",
    "data_streams": [
      "reckUcHRJywW08ZsE"
    ],
    "phase": "production",
    "lens_uid": "1-demo-vib",
    "account": [
      "recOTPZbECMahKTAz"
    ],
    "resources": "recovib-io-demo",
    "lens_connectors": [
      "recExonK09DUEZqZG",
      "recnoPf5EQXm3nLfB",
      "recprxWlhzB6BVtON",
      "rectw95YARj8iYSsZ",
      "rec2MeyBXl8g3wKP6",
      "recS0GG1kCMmOPCgb",
      "recHB5XhNNA9nJCQ5"
    ],
    "slug": "demo_mode__1-demo-vib",
    "status": "active",
    "_id": "recgjNz3HAVWQol3G"
  },
  {
    "i18n": "Sensitive Equipment - Vibration Monitoring",
    "data_streams": [
      "rec3ecsrSjOrHzHm3"
    ],
    "phase": "disconnected",
    "lens_uid": "1-vib",
    "account": [
      "recOF6J4OlWNy7GDq"
    ],
    "resources": "pr202-azure-website",
    "lens_connectors": [
      "recoktADoUFkOSaAU",
      "recpGm1wYswHUw8Ga"
    ],
    "slug": "bierset-ground-radar__1-vib",
    "status": "closed",
    "_id": "recYc2iarob6sf1t2"
  },
  {
    "i18n": "Building vibration ",
    "data_streams": [
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS"
    ],
    "phase": "disconnected",
    "lens_uid": "1-vib-resident",
    "account": [
      "recSFOMDKLj2oJzV5"
    ],
    "resources": "pr202-azure-website",
    "lens_connectors": [
      "recWhwav8YP91MzWR",
      "recYgJ4msI00YosSK"
    ],
    "slug": "thomas-piron-mons__1-vib-resident",
    "status": "closed",
    "_id": "rec2cKlJnOyliRULH"
  },
  {
    "i18n": "Building vibration & dust monitoring",
    "data_streams": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "phase": "disconnected",
    "lens_uid": "1-dust+vib",
    "account": [
      "recQ34ASV3lwqzNSl"
    ],
    "resources": "pr202-azure-website",
    "lens_connectors": [
      "recaatwOQ589xdX3g",
      "recAhSn5pBAQhJA6s"
    ],
    "slug": "ec-museum__1-dust+vib",
    "status": "closed",
    "_id": "rec0A09Yy6APkH8IX"
  },
  {
    "i18n": "Building vibration",
    "data_streams": [
      "recHwP0ILtAabECEi"
    ],
    "phase": "disconnected",
    "lens_uid": "1-vib",
    "account": [
      "recrjvchXZbJJcfLN"
    ],
    "resources": "pr202-azure-website",
    "lens_connectors": [
      "recsLjxOl9OY1xkbF",
      "rec3Boax9ti7nEAWy"
    ],
    "slug": "edith-cavel-hospital__1-vib",
    "status": "closed",
    "_id": "recBQrLnA2q2DkABp"
  },
  {
    "i18n": "Tuned mass damper efficiency",
    "data_streams": [
      "rec5koTRpeIhrfG5z",
      "recutQQkZozG03J1k",
      "recawV8HyIaUQ5NEJ"
    ],
    "phase": "proposed",
    "lens_uid": "1-tmd",
    "account": [
      "recT2vVyw3ad0WrwV"
    ],
    "slug": "walibi-nl-roller-coaster__1-tmd",
    "status": "closed",
    "_id": "rec3zruE96pwU4Mmx"
  },
  {
    "i18n": "Tuned mass damper efficiency",
    "data_streams": [
      "recuVcKLn40Ht1dic",
      "rec0G4e9Of8dT3l4z",
      "recL98fqNk8w4GztK"
    ],
    "phase": "proposed",
    "lens_uid": "1-tmd",
    "account": [
      "rec4SG1ItfDoPEVCe"
    ],
    "slug": "flagpole__1-tmd",
    "status": "closed",
    "_id": "recepCAO6iSHJMgsQ"
  },
  {
    "i18n": "Rails conditions",
    "data_streams": [
      "rec7lvV7qL8pFZMnX",
      "recQ376QP3DSjJwW9",
      "recCFmt0tbh80DQfe",
      "reck52xR8r2RfJwbt",
      "rec34grJUu0O5zSov",
      "receLHTYTEsMdL7hr"
    ],
    "phase": "proposed",
    "lens_uid": "1-mon",
    "account": [
      "recj3JPqm7bpHHJQz"
    ],
    "slug": "melb-heaven-rails__1-mon",
    "status": "closed",
    "_id": "recfbVXhr6RFrhbkl"
  },
  {
    "i18n": "[Peak] Crane - Maher Terminals/New York/Rails vibrations",
    "data_streams": [
      "rec9ACuocNTdBtpVh"
    ],
    "phase": "production",
    "lens_uid": "1-acc",
    "account": [
      "recafuoFGwifWLdA0"
    ],
    "resources": "recovib-io-mmd, recovib-io-demo",
    "lens_connectors": [
      "recghQAuFEgiAcvvi",
      "recrD1uH7imUTk0E2",
      "recH3XkiH6sbxiExA"
    ],
    "slug": "ny-heaven-rails__1-acc",
    "status": "active",
    "_id": "recIZvFrLqOzmiidA"
  },
  {
    "i18n": "[Bursts] Crane - Maher Terminals/New York/Rails vibrations",
    "data_streams": [
      "recvPdRExK39JEKhZ"
    ],
    "phase": "production",
    "lens_uid": "1-burst",
    "account": [
      "recafuoFGwifWLdA0"
    ],
    "resources": "recovib-io-mmd, recovib-io-demo",
    "lens_connectors": [
      "reccRij0Z9SAN0qcJ",
      "recubsyhm7Omz0p4J",
      "rectDb9oL5pPoeMUN"
    ],
    "slug": "ny-heaven-rails__1-burst",
    "status": "active",
    "_id": "recv1HUNAKeTgPjp7"
  },
  {
    "i18n": "[RMS-Staging] Crane - Maher Terminals/New York/Rails vibrations",
    "data_streams": [
      "reciz2H1wHyUuOqZ4"
    ],
    "phase": "staging",
    "lens_uid": "1-acc-stag",
    "account": [
      "rec1bgCaP4j9kR0Nl"
    ],
    "resources": "recovib-io-mmd, recovib-io-demo",
    "lens_connectors": [
      "reccRij0Z9SAN0qcJ",
      "recubsyhm7Omz0p4J",
      "rectDb9oL5pPoeMUN"
    ],
    "slug": "ny-heaven-rails-staging__1-acc-stag",
    "status": "active",
    "_id": "recb0iGXNF4ttgsqy"
  },
  {
    "i18n": "[Bursts-Staging] Crane - Maher Terminals/New York/Rails vibrations",
    "data_streams": [
      "rec1wF7yIam4ttWgp"
    ],
    "phase": "staging",
    "lens_uid": "1-burst-stag",
    "account": [
      "rec1bgCaP4j9kR0Nl"
    ],
    "resources": "recovib-io-mmd, recovib-io-demo",
    "lens_connectors": [
      "reccRij0Z9SAN0qcJ",
      "recubsyhm7Omz0p4J",
      "rectDb9oL5pPoeMUN"
    ],
    "slug": "ny-heaven-rails-staging__1-burst-stag",
    "status": "active",
    "_id": "recHe0EGSjf5E5Pdi"
  },
  {
    "i18n": "Crane - Le Havre",
    "phase": "proposed",
    "lens_uid": "1-mon",
    "account": [
      "recA65tYVI8HFwvYO"
    ],
    "slug": "hv-heaven-rails__1-mon",
    "status": "closed",
    "_id": "rec3TgAKbl8FfV3kM"
  },
  {
    "i18n": "Pylons structural monitoring",
    "data_streams": [
      "recmWXXG0xuwp70TR"
    ],
    "phase": "proposed",
    "lens_uid": "1-str",
    "account": [
      "rec4L58te2VjL3aX1"
    ],
    "slug": "gsm-pylon-structural-integrity__1-str",
    "status": "closed",
    "_id": "recei1HzR5aCFbvND"
  },
  {
    "i18n": "Wind turbines integrity monitoring",
    "data_streams": [
      "recc1TqHR12GLM5z2"
    ],
    "phase": "proposed",
    "lens_uid": "1-str",
    "account": [
      "rechTfkxQZ5CFWPak"
    ],
    "slug": "wind-turbine__1-str",
    "status": "closed",
    "_id": "recrqbTDt2kVz4a0W"
  },
  {
    "i18n": "Cooling system condition monitoring",
    "data_streams": [
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e"
    ],
    "phase": "proposed",
    "lens_uid": "1-mon",
    "account": [
      "rec1NUB2Pul5jJvou"
    ],
    "slug": "cooling-system__1-mon",
    "status": "closed",
    "_id": "recbkQa8sxAodRQe6"
  },
  {
    "i18n": "Structural Monitoring",
    "data_streams": [
      "rectLCYTwsTaHkC5A",
      "recKn1WnMnQN0iHVL",
      "recRXv1BZvV00joAF",
      "recS9FMg5nuJMRTFQ",
      "rechvayE0wvGwSXmu",
      "rec7ZdOkSsxjBjo0K",
      "recPbV9HJT2FKjndK"
    ],
    "phase": "proposed",
    "lens_uid": "1-str",
    "account": [
      "recU7Na7qndvBDpEq"
    ],
    "slug": "mumbai-pagoda__1-str",
    "status": "closed",
    "_id": "rec4EJJd3qsOvLKu2"
  },
  {
    "i18n": "Building Vibration",
    "data_streams": [
      "recaFT8ptuaTfSNaP"
    ],
    "phase": "production",
    "lens_uid": "1-vib",
    "account": [
      "recD2EAEwb6MhJEtz"
    ],
    "resources": "recovib-io-mmd, recovib-io-demo",
    "lens_connectors": [
      "recCsLFifOKBt8Q6E",
      "recNWJtiub8xBgEbv",
      "recaapCdi0pSqFWIt",
      "recXkcZ21M1PR1w5L",
      "recJ8oBbCRwymlPGq",
      "reccLl0jO4PKkoXAs",
      "recTjOYYO7rIRsmnE",
      "rec2id9JCPuNPCeEH"
    ],
    "slug": "pommeroeul-railways__1-vib",
    "status": "active",
    "_id": "recNzA9K9el5bRZjb"
  },
  {
    "i18n": "Pipe Vibration",
    "data_streams": [
      "recR2THdoCs07Sdpg"
    ],
    "phase": "production",
    "lens_uid": "1-vib",
    "account": [
      "recC9PrkPLM7Ngxr9"
    ],
    "lens_connectors": [
      "recbMbJfYzEXlHpVK",
      "recGj4mUjOz3CKPlj",
      "recXCWPPOvlfbuISk"
    ],
    "slug": "cementys-1st-account__1-vib",
    "status": "active",
    "_id": "recsHz6kCv6YROaxd"
  },
  {
    "i18n": "Pipe Vibration",
    "data_streams": [
      "recBwNAToeCXDLxJk"
    ],
    "phase": "production",
    "lens_uid": "1-vib",
    "account": [
      "recLQq6bzpji0kgqa"
    ],
    "lens_connectors": [
      "recLDmmkFv5iUcUYn",
      "recd37LcD36QIPDXE",
      "recYR4m7TQCy7U50s"
    ],
    "slug": "vmv-1st-account__1-vib",
    "status": "active",
    "_id": "recMKc4UX6KSh95wA"
  },
  {
    "i18n": "Sensitive Equipment - Vibration Monitoring",
    "phase": "disconnected",
    "lens_uid": "1-vib",
    "account": [
      "recn2m6fU3RYHUijA"
    ],
    "slug": "vmv-closed-account__1-vib",
    "status": "closed",
    "_id": "recgk3TzUss2xCX7U"
  },
  {
    "i18n": "Source 1",
    "data_streams": [
      "recQ9ZRXIQun3SyEQ",
      "recvlVClYhmHOyCi9",
      "recxm7gkL7yFoP92K",
      "recKTP0AWnhoBcQEm"
    ],
    "phase": "staging",
    "lens_uid": "1-vib",
    "account": [
      "recmdJRvcoD0kbHOB"
    ],
    "lens_connectors": [
      "recQTIVJC7DPt5cdI",
      "recbSbAIWYbXEA3wz",
      "reclXdVLVJxjbSNnC",
      "recjJLOT8jNBmv2kV"
    ],
    "slug": "travelec__1-vib",
    "status": "active",
    "_id": "recpUl6IA69vz2tFh"
  },
  {
    "i18n": "Temperature Measurement",
    "data_streams": [
      "reckMmnVlOWlhB9hA"
    ],
    "phase": "staging",
    "lens_uid": "1-tmp",
    "account": [
      "recBxCcwub9NS2IvT"
    ],
    "slug": "offtech-temperature__1-tmp",
    "status": "active",
    "_id": "recX6OkxfrCqldLhP"
  },
  {
    "i18n": "Vibration Statistics - VP16 -Temporary Portal Slope",
    "data_streams": [
      "recT76yaISvkZ9Flg"
    ],
    "phase": "production",
    "lens_uid": "1-vib",
    "account": [
      "recqQeSjl4XFHkoLN"
    ],
    "lens_connectors": [
      "recsqwPNBcVcJdxTi"
    ],
    "slug": "transcendence__1-vib",
    "status": "active",
    "_id": "recBDu3XnWSufJpT7"
  },
  {
    "i18n": "Vibration Statistics - VP1 - Existing grave",
    "data_streams": [
      "rec2jaMt6cbdZFin4"
    ],
    "phase": "production",
    "lens_uid": "2-vib",
    "account": [
      "recqQeSjl4XFHkoLN"
    ],
    "lens_connectors": [
      "recUbeUmAGEY5JIX8"
    ],
    "slug": "transcendence__2-vib",
    "status": "active",
    "_id": "recJnlDm6hayL2X4o"
  },
  {
    "i18n": "Vibration Blast - VP16 -Temporary Portal Slope",
    "data_streams": [
      "recx9Ja6uStoIPEB8"
    ],
    "phase": "production",
    "lens_uid": "1-bst",
    "account": [
      "recqQeSjl4XFHkoLN"
    ],
    "lens_connectors": [
      "rec5EgGkiVbt3xlwH",
      "recsqwPNBcVcJdxTi"
    ],
    "slug": "transcendence__1-bst",
    "status": "active",
    "_id": "recE6B2uUt8VKHjbu"
  },
  {
    "i18n": "Vibration Blast - VP1 - Existing grave",
    "data_streams": [
      "recwbGBG6A55hHHWo"
    ],
    "phase": "production",
    "lens_uid": "2-bst",
    "account": [
      "recqQeSjl4XFHkoLN"
    ],
    "lens_connectors": [
      "recxlaz2bYhiqZ0KC",
      "recUbeUmAGEY5JIX8"
    ],
    "slug": "transcendence__2-bst",
    "status": "active",
    "_id": "recpphl6SiHo4lAny"
  },
  {
    "i18n": "Transcendence All Reports",
    "phase": "production",
    "lens_uid": "reports",
    "account": [
      "recqQeSjl4XFHkoLN"
    ],
    "slug": "transcendence__reports",
    "status": "active",
    "_id": "recHDSfE4RPKaINFh"
  }
]