export const table = [
  {
    "slug": "lho-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recatUzBFGucIqKLk",
      "recwui7P1WhFDcRJk",
      "recKNVffgsPVAmwzg",
      "recbNHAVopQYizcp4",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "recgSCKxwVbEzKKaF",
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "recIItmf6jCeyz3Cl"
    ],
    "lenses": [
      "rechsvgMd5jSeJXMs"
    ],
    "status": "Active",
    "Description": "Building Vibrations DIN4150-3/L2 VB2 (mm/s)",
    "lens_blocks": [
      "recu43lwYpX2WT5bp",
      "recu5g3sbcMgW4aSk",
      "recVvQ0rmYRkcIGZB",
      "receZKVU9apHKyhJz",
      "recyIicFg05dAFZMs",
      "recdoHzILrmvxc7yN",
      "recUZNuN8Aw7sTdvl",
      "recksTYwFHNJAcPqg",
      "recHbr75IONoPIctr"
    ],
    "app": "{\n  \"beat-name\": \"10A16\",\n  \"burst-name\": \"10A17\",\n  \"dump-name\": \"10A|d+\",\n  \"partitionKey\": \"[\\\"10\\\"]\",\n  \"conditions\": [{\n    \"before\": \"2020-02-24T23:00:00.000Z\",\n    \"beat-name\": \"10A16\",\n    \"burst-name\": \"10A17\",\n    \"dump-name\": \"10A|d+\",\n    \"partitionKey\": \"[\\\"10\\\"]\",\n    \"formatter\": \"vibretro\"\n  }]\n}\n",
    "configs": [
      "recbREsdcaFOURhw8",
      "recOJKmNsxEYqQw5p",
      "rectZJcAUb3moPaHJ"
    ],
    "_id": "recIBDky5zeo675eG"
  },
  {
    "slug": "lho-2-air",
    "setups": [
      "recatUzBFGucIqKLk",
      "recrWi63kzpzfMGeP",
      "rec0jxT6BUsYQbVKW",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "reccVDMc5e3jgFdBo",
      "rec5cZpt1AQit2uXG",
      "recwui7P1WhFDcRJk",
      "recmSgo8BCqGZUwo3",
      "recRqjvYlJAlgElwq"
    ],
    "devices": [
      "recwVhY4xbaRopjQA"
    ],
    "lenses": [
      "recJMXontAqJzsg8H"
    ],
    "status": "Active",
    "Description": "Peak Air Pressure (Pa)",
    "lens_blocks": [
      "recscoYyaBJ9LnMLD",
      "recvaN9hrVcjoyPMj"
    ],
    "app": "{\n  \"beat-name\": \"Air fast\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recGrH5a79W6fihEg",
      "recngNqbEkiYWt5Gz"
    ],
    "_id": "recj2lS80a8DGR9hB"
  },
  {
    "slug": "nyh-1-vib",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "status": "Inactive",
    "Description": "accelerometer BottomLeft",
    "_id": "recYZJgbD3tzJmlix"
  },
  {
    "slug": "pom-1-vib",
    "setups": [
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "rec0iPAkXTIEDWbBu"
    ],
    "lenses": [
      "recNzA9K9el5bRZjb"
    ],
    "status": "Active",
    "Description": "Vibrations DIN4150-3/L2 VB2 (mm/s)",
    "lens_blocks": [
      "recJ8oBbCRwymlPGq",
      "recXkcZ21M1PR1w5L",
      "recTjOYYO7rIRsmnE",
      "rec2id9JCPuNPCeEH",
      "recaapCdi0pSqFWIt",
      "reccLl0jO4PKkoXAs",
      "recNWJtiub8xBgEbv",
      "recCsLFifOKBt8Q6E"
    ],
    "app": "{\n  \"beat-name\": \"Railways\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recGrH5a79W6fihEg",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recaFT8ptuaTfSNaP"
  },
  {
    "slug": "mbh-24-ac",
    "setups": [
      "rec9msLpL4R9tceAO",
      "reccVDMc5e3jgFdBo"
    ],
    "status": "Inactive",
    "Description": "Air pressure (infrasound from 2Hz to 250Hz).",
    "_id": "recKI0KlGZoDKSCMz"
  },
  {
    "slug": "nyh-1-acc",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "devices": [
      "receemHKTwt0AtSjH"
    ],
    "lenses": [
      "recIZvFrLqOzmiidA"
    ],
    "status": "Active",
    "Description": "Peak Acceleration (g)",
    "lens_blocks": [
      "recH3XkiH6sbxiExA",
      "recrD1uH7imUTk0E2",
      "recghQAuFEgiAcvvi"
    ],
    "app": "{\n  \"beat-name\": \"steam|equal|chair|cargo\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "rec7SFE16NDahjr6j",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "rec9ACuocNTdBtpVh"
  },
  {
    "slug": "demo-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recatUzBFGucIqKLk",
      "recwui7P1WhFDcRJk",
      "recKNVffgsPVAmwzg",
      "recbNHAVopQYizcp4",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "recgSCKxwVbEzKKaF",
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "recIItmf6jCeyz3Cl"
    ],
    "lenses": [
      "recgjNz3HAVWQol3G"
    ],
    "status": "Active",
    "Description": "Warnings and alarms if vibration exceeds given levels. Periodic bursts for FFT computations ",
    "lens_blocks": [
      "rec2MeyBXl8g3wKP6",
      "rectw95YARj8iYSsZ",
      "recHB5XhNNA9nJCQ5",
      "recprxWlhzB6BVtON",
      "recS0GG1kCMmOPCgb",
      "recnoPf5EQXm3nLfB",
      "recExonK09DUEZqZG"
    ],
    "app": "{\n  \"beat-name\": \"10A16\",\n  \"burst-name\": \"10A17\",\n  \"dump-name\": \"10A|d+\",\n  \"partitionKey\": \"[\\\"10\\\"]\",\n  \"exceptions\": [{\n    \"before\": \"2020-02-24T23:00:00.000Z\",\n    \"beat-name\": \"10A16\",\n    \"burst-name\": \"10A17\",\n    \"dump-name\": \"10A|d+\",\n    \"partitionKey\": \"[\\\"10\\\"]\",\n    \"formatter\": \"vibretro\"\n  }]\n}\n",
    "configs": [
      "recbREsdcaFOURhw8",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "reckUcHRJywW08ZsE"
  },
  {
    "slug": "demo-2-air",
    "setups": [
      "recatUzBFGucIqKLk",
      "recrWi63kzpzfMGeP",
      "rec0jxT6BUsYQbVKW",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "reccVDMc5e3jgFdBo",
      "rec5cZpt1AQit2uXG",
      "recwui7P1WhFDcRJk",
      "recmSgo8BCqGZUwo3",
      "recRqjvYlJAlgElwq"
    ],
    "devices": [
      "recwVhY4xbaRopjQA"
    ],
    "lenses": [
      "recDkwA4Ue0InS7aW"
    ],
    "status": "Active",
    "Description": "DIN4150-3/L2 with 1-250Hz bandpass filter ",
    "lens_blocks": [
      "recHIl3dgsotygldB",
      "recAoimMe99XzsrOO"
    ],
    "app": "{\n  \"beat-name\": \"Air fast\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recGrH5a79W6fihEg",
      "recngNqbEkiYWt5Gz"
    ],
    "_id": "recOL4qWlaN3S6u0S"
  },
  {
    "slug": "cem-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recatUzBFGucIqKLk",
      "recwui7P1WhFDcRJk",
      "recKNVffgsPVAmwzg",
      "recbNHAVopQYizcp4",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "recgSCKxwVbEzKKaF",
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "rec48pjqsp4PTU4on"
    ],
    "lenses": [
      "recsHz6kCv6YROaxd"
    ],
    "status": "Active",
    "Description": "Peak Vibration Velocity [1-100Hz] (mm/s)",
    "lens_blocks": [
      "recXCWPPOvlfbuISk",
      "recGj4mUjOz3CKPlj",
      "recbMbJfYzEXlHpVK"
    ],
    "app": "{\n  \"beat-name\": \"femur\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}\n",
    "configs": [
      "recwCEroolP6OuJDg",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recR2THdoCs07Sdpg"
  },
  {
    "slug": "vmv-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recatUzBFGucIqKLk",
      "recwui7P1WhFDcRJk",
      "recKNVffgsPVAmwzg",
      "recbNHAVopQYizcp4",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "recgSCKxwVbEzKKaF",
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "lenses": [
      "recMKc4UX6KSh95wA"
    ],
    "status": "Active",
    "Description": "Peak Vibration Velocity [1-100Hz] (mm/s)",
    "lens_blocks": [
      "recYR4m7TQCy7U50s",
      "recd37LcD36QIPDXE",
      "recLDmmkFv5iUcUYn"
    ],
    "app": "{\n  \"beat-name\": \"worry\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}\n",
    "configs": [
      "recX8I10U29Px4Ct9",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recBwNAToeCXDLxJk"
  },
  {
    "slug": "tvc-1-vib",
    "devices": [
      "recw97ytmeqaDVyiD"
    ],
    "lenses": [
      "recpUl6IA69vz2tFh"
    ],
    "status": "Active",
    "Description": "Velocity RMS [10-1000Hz] (mm/s)",
    "lens_blocks": [
      "recjJLOT8jNBmv2kV",
      "reclXdVLVJxjbSNnC",
      "recbSbAIWYbXEA3wz",
      "recQTIVJC7DPt5cdI"
    ],
    "app": "{\n  \"beat-name\": \"agony1\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recOJKmNsxEYqQw5p",
      "rec7SFE16NDahjr6j"
    ],
    "_id": "recQ9ZRXIQun3SyEQ"
  },
  {
    "slug": "tvc-2-vib",
    "devices": [
      "recw97ytmeqaDVyiD"
    ],
    "lenses": [
      "recpUl6IA69vz2tFh"
    ],
    "status": "Active",
    "Description": "Velocity RMS [10-1000Hz] (mm/s)",
    "lens_blocks": [
      "recjJLOT8jNBmv2kV",
      "reclXdVLVJxjbSNnC",
      "recbSbAIWYbXEA3wz",
      "recQTIVJC7DPt5cdI"
    ],
    "app": "{\n  \"beat-name\": \"agony2\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recOJKmNsxEYqQw5p",
      "rec7SFE16NDahjr6j"
    ],
    "_id": "recvlVClYhmHOyCi9"
  },
  {
    "slug": "tvc-3-vib",
    "devices": [
      "recw97ytmeqaDVyiD"
    ],
    "lenses": [
      "recpUl6IA69vz2tFh"
    ],
    "status": "Active",
    "Description": "Velocity RMS [10-1000Hz] (mm/s)",
    "lens_blocks": [
      "recjJLOT8jNBmv2kV",
      "reclXdVLVJxjbSNnC",
      "recbSbAIWYbXEA3wz",
      "recQTIVJC7DPt5cdI"
    ],
    "app": "{\n  \"beat-name\": \"agony3\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recOJKmNsxEYqQw5p",
      "rec7SFE16NDahjr6j"
    ],
    "_id": "recxm7gkL7yFoP92K"
  },
  {
    "slug": "tvc-4-vib",
    "devices": [
      "recw97ytmeqaDVyiD"
    ],
    "lenses": [
      "recpUl6IA69vz2tFh"
    ],
    "status": "Active",
    "Description": "Velocity RMS [10-1000Hz] (mm/s)",
    "lens_blocks": [
      "recjJLOT8jNBmv2kV",
      "reclXdVLVJxjbSNnC",
      "recbSbAIWYbXEA3wz",
      "recQTIVJC7DPt5cdI"
    ],
    "app": "{\n  \"beat-name\": \"agony4\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recOJKmNsxEYqQw5p",
      "rec7SFE16NDahjr6j"
    ],
    "_id": "recKTP0AWnhoBcQEm"
  },
  {
    "slug": "bie-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recLW0jq4FFmDOzwD",
      "recKNVffgsPVAmwzg",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "rechmZz2REFVNOgfu"
    ],
    "lenses": [
      "recYc2iarob6sf1t2"
    ],
    "status": "Inactive",
    "Description": "Warning and Alarms (actions vs stop work)",
    "lens_blocks": [
      "recpGm1wYswHUw8Ga",
      "recoktADoUFkOSaAU"
    ],
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "rec3ecsrSjOrHzHm3"
  },
  {
    "slug": "thp-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recg6dYBy1bLV5L1X",
      "recKNVffgsPVAmwzg",
      "recgSCKxwVbEzKKaF",
      "recGP5T2pI5c001gq",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "devices": [
      "rechNbDZPK0fCF55l"
    ],
    "lenses": [
      "rec2cKlJnOyliRULH"
    ],
    "status": "Inactive",
    "Description": "Monitoring following DIN4150-3/L2 (normal buildings). Comparison with ACOEM Orion ",
    "lens_blocks": [
      "recYgJ4msI00YosSK",
      "recWhwav8YP91MzWR"
    ],
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "recq6RoQjskbkCgv8"
  },
  {
    "slug": "thp-2-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recg6dYBy1bLV5L1X",
      "recKNVffgsPVAmwzg",
      "recgSCKxwVbEzKKaF",
      "rec1PnR9H0tyl1V2Z",
      "recmyNahgfmPYnjKy",
      "recmSgo8BCqGZUwo3",
      "recaw1BDDe1wduoEt"
    ],
    "lenses": [
      "rec2cKlJnOyliRULH"
    ],
    "status": "Inactive",
    "Description": "Monitoring following DIN4150-3/L3 (sensitive buildings). Comparison with ACOEM Orion.",
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "recGkdxPB7IAVK7SS"
  },
  {
    "slug": "ecm-1-vib",
    "setups": [
      "recoHXo6481igyAih",
      "recUzWxXqUFbDQxGh",
      "rec5HXkS10mC1Cuic",
      "recFoe4IasbXeB5Om",
      "rechl18CctApcCtVQ",
      "recYjtLtvvOl4z0z4",
      "recisokkw1Hb1lvTW",
      "reck0pK17FgXMs4Vz",
      "recKNVffgsPVAmwzg",
      "rechqMU6WOfaYO6MS",
      "recboIXzIXpJCcVeu",
      "rechICH7tz6RmoEpr",
      "recmSgo8BCqGZUwo3",
      "recGLTiz5RGdL3Tc9",
      "rec1PnR9H0tyl1V2Z"
    ],
    "lenses": [
      "rec0A09Yy6APkH8IX"
    ],
    "status": "Inactive",
    "Description": "Building vibration according to DIN4150-3/L3 (sensitive buildings). ",
    "lens_blocks": [
      "recAhSn5pBAQhJA6s",
      "recaatwOQ589xdX3g"
    ],
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "recWkL3V9LeY0PBo4"
  },
  {
    "slug": "ecm-2-dst",
    "setups": [
      "recUzWxXqUFbDQxGh",
      "rec5HXkS10mC1Cuic",
      "recFoe4IasbXeB5Om",
      "rechl18CctApcCtVQ",
      "reck0pK17FgXMs4Vz",
      "recYjtLtvvOl4z0z4",
      "recisokkw1Hb1lvTW",
      "recjEmTDdzEpQdIes",
      "rechqMU6WOfaYO6MS",
      "recboIXzIXpJCcVeu",
      "rechICH7tz6RmoEpr",
      "recmSgo8BCqGZUwo3",
      "recGLTiz5RGdL3Tc9",
      "rec4UxwB1AMhRyGtu"
    ],
    "lenses": [
      "rec0A09Yy6APkH8IX"
    ],
    "status": "Inactive",
    "Description": "Voltage input for a Casella Dust Monitoring Device. ",
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "rectWJ2AI1vs4gy8J"
  },
  {
    "slug": "ecv-1-vib",
    "setups": [
      "recisokkw1Hb1lvTW",
      "recLW0jq4FFmDOzwD",
      "recnxYlASdFJhbEXn",
      "recboIXzIXpJCcVeu",
      "rechl18CctApcCtVQ",
      "recYjtLtvvOl4z0z4",
      "recKNVffgsPVAmwzg",
      "rec5cZpt1AQit2uXG",
      "recgSCKxwVbEzKKaF",
      "rec1PnR9H0tyl1V2Z"
    ],
    "lenses": [
      "recBQrLnA2q2DkABp"
    ],
    "status": "Inactive",
    "Description": "Building Vibration Measurement according to DIN4150-3/L3",
    "lens_blocks": [
      "rec3Boax9ti7nEAWy",
      "recsLjxOl9OY1xkbF"
    ],
    "configs": [
      "recbREsdcaFOURhw8",
      "recqz4fnV3qDX5xbU"
    ],
    "_id": "recHwP0ILtAabECEi"
  },
  {
    "slug": "wnl-1-tmd",
    "lenses": [
      "rec3zruE96pwU4Mmx"
    ],
    "status": "Inactive",
    "Description": "Accelerometer #1 on the Roller Coaster Structure ",
    "_id": "rec5koTRpeIhrfG5z"
  },
  {
    "slug": "wnl-2-tmd",
    "lenses": [
      "rec3zruE96pwU4Mmx"
    ],
    "status": "Inactive",
    "Description": "Accelerometer #2 on the Roller Coaster Structure ",
    "_id": "recutQQkZozG03J1k"
  },
  {
    "slug": "wnl-3-tmd",
    "setups": [
      "rechDSmu9ljpMnaVg",
      "recxPootjl5yNhz5p",
      "rechl18CctApcCtVQ",
      "reck0pK17FgXMs4Vz",
      "recYjtLtvvOl4z0z4",
      "recisokkw1Hb1lvTW",
      "recgSCKxwVbEzKKaF",
      "rec5cZpt1AQit2uXG",
      "reclEmVTkbAZVg1Lw"
    ],
    "lenses": [
      "rec3zruE96pwU4Mmx"
    ],
    "status": "Inactive",
    "Description": "Accelerometer on the TMD moving mass  ",
    "_id": "recawV8HyIaUQ5NEJ"
  },
  {
    "slug": "fgp-1-acc",
    "lenses": [
      "recepCAO6iSHJMgsQ"
    ],
    "status": "Inactive",
    "Description": "Accelerations measurements on Flagpole",
    "_id": "recuVcKLn40Ht1dic"
  },
  {
    "slug": "fgp-2-dis",
    "lenses": [
      "recepCAO6iSHJMgsQ"
    ],
    "status": "Inactive",
    "Description": "Displacements measurements  on Flagpole",
    "_id": "rec0G4e9Of8dT3l4z"
  },
  {
    "slug": "fgp-1-tmd",
    "lenses": [
      "recepCAO6iSHJMgsQ"
    ],
    "status": "Inactive",
    "Description": "Dedicated algorithm for TMDs efficiency computations. ",
    "_id": "recL98fqNk8w4GztK"
  },
  {
    "slug": "mbh-1-pot",
    "setups": [
      "reclmn4XnaSfHWq3B",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One string potentiometer sensor per rail. ",
    "_id": "rec7lvV7qL8pFZMnX"
  },
  {
    "slug": "mbh-12-tem",
    "setups": [
      "recUty6BjCcM4jx4o",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One surface temperature sensor per rail. ",
    "_id": "recQ376QP3DSjJwW9"
  },
  {
    "slug": "gsm-1-tem",
    "setups": [
      "recg6dYBy1bLV5L1X",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "lenses": [
      "recei1HzR5aCFbvND"
    ],
    "status": "Inactive",
    "Description": "Operating temperature range for sensors (from -40°C, -55°C in a few places). ",
    "_id": "recmWXXG0xuwp70TR"
  },
  {
    "slug": "wnd-1-acc",
    "setups": [
      "recGLTiz5RGdL3Tc9",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recrqbTDt2kVz4a0W"
    ],
    "status": "Inactive",
    "Description": "2-axis IAC-I accelerometer",
    "_id": "recc1TqHR12GLM5z2"
  },
  {
    "slug": "coo-1-cnd",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recCYcT1SEOBREETJ",
      "recmSgo8BCqGZUwo3",
      "recyIkVIMQkSEBfhm",
      "recGLTiz5RGdL3Tc9",
      "recmYNTij4EbkpLMK"
    ],
    "lenses": [
      "recbkQa8sxAodRQe6"
    ],
    "status": "Inactive",
    "Description": "Remote monitoring from everywhere. Up to 500Hz useful bandwidth ",
    "_id": "rec6hMXsNuwKMpEvG"
  },
  {
    "slug": "coo-2-cnd",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recCYcT1SEOBREETJ",
      "recmSgo8BCqGZUwo3",
      "recyIkVIMQkSEBfhm",
      "recGLTiz5RGdL3Tc9",
      "recmYNTij4EbkpLMK"
    ],
    "lenses": [
      "recbkQa8sxAodRQe6"
    ],
    "status": "Inactive",
    "Description": "Remote monitoring from everywhere. Up to 500Hz useful bandwidth ",
    "_id": "recrezFVIAeIXUWuN"
  },
  {
    "slug": "coo-3-cnd",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recCYcT1SEOBREETJ",
      "recmSgo8BCqGZUwo3",
      "recyIkVIMQkSEBfhm",
      "recGLTiz5RGdL3Tc9",
      "recmYNTij4EbkpLMK"
    ],
    "lenses": [
      "recbkQa8sxAodRQe6"
    ],
    "status": "Inactive",
    "Description": "Remote monitoring from everywhere. Up to 500Hz useful bandwidth ",
    "_id": "rec9Ncv41AXPiuoJ4"
  },
  {
    "slug": "coo-4-cnd",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recCYcT1SEOBREETJ",
      "recmSgo8BCqGZUwo3",
      "recyIkVIMQkSEBfhm",
      "recGLTiz5RGdL3Tc9",
      "recmYNTij4EbkpLMK"
    ],
    "lenses": [
      "recbkQa8sxAodRQe6"
    ],
    "status": "Inactive",
    "Description": "Remote monitoring from everywhere. Up to 500Hz useful bandwidth ",
    "_id": "recnkL8EuvfNToT6e"
  },
  {
    "slug": "mbh-2-pot",
    "setups": [
      "reclmn4XnaSfHWq3B",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One string potentiometer sensor per rail. ",
    "_id": "recCFmt0tbh80DQfe"
  },
  {
    "slug": "mbh-3-pot",
    "setups": [
      "reclmn4XnaSfHWq3B",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One string potentiometer sensor per rail. ",
    "_id": "reck52xR8r2RfJwbt"
  },
  {
    "slug": "mbh-14-tem",
    "setups": [
      "recUty6BjCcM4jx4o",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One surface temperature sensor per rail. ",
    "_id": "rec34grJUu0O5zSov"
  },
  {
    "slug": "mbh-15-tem",
    "setups": [
      "recUty6BjCcM4jx4o",
      "recGLTiz5RGdL3Tc9",
      "rec3sn2cAAMGrGXI7",
      "recmSgo8BCqGZUwo3"
    ],
    "lenses": [
      "recfbVXhr6RFrhbkl"
    ],
    "status": "Inactive",
    "Description": "One surface temperature sensor per rail. ",
    "_id": "receLHTYTEsMdL7hr"
  },
  {
    "slug": "pgd-1-acc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ",
    "_id": "rectLCYTwsTaHkC5A"
  },
  {
    "slug": "pgd-20-inc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "3 x inclinometer (without temperature compensation)",
    "_id": "recS9FMg5nuJMRTFQ"
  },
  {
    "slug": "pgd-2-acc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ",
    "_id": "recKn1WnMnQN0iHVL"
  },
  {
    "slug": "pgd-3-acc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ",
    "_id": "recRXv1BZvV00joAF"
  },
  {
    "slug": "pgd-21-inc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "3 x inclinometer (without temperature compensation)",
    "_id": "rechvayE0wvGwSXmu"
  },
  {
    "slug": "pgd-22-inc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "3 x inclinometer (without temperature compensation)",
    "_id": "rec7ZdOkSsxjBjo0K"
  },
  {
    "slug": "pgd-4-acc",
    "setups": [
      "recB8BAts2FtXbYnm",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG",
      "rec9msLpL4R9tceAO"
    ],
    "lenses": [
      "rec4EJJd3qsOvLKu2"
    ],
    "status": "Inactive",
    "Description": "Synchronized measurement across acceleration channels  Periodical bursts for structural analysis. 3 x 2-axis accelerometers at 3 different levels on the pagoda (I.e. long cables). ",
    "_id": "recPbV9HJT2FKjndK"
  },
  {
    "slug": "mbh-21-ac",
    "setups": [
      "rec9msLpL4R9tceAO"
    ],
    "status": "Inactive",
    "Description": "accelerometer FrontLeft",
    "_id": "recsFyD07uSK9FPuN"
  },
  {
    "slug": "mbh-22-ac",
    "setups": [
      "rec9msLpL4R9tceAO",
      "reccVDMc5e3jgFdBo"
    ],
    "status": "Inactive",
    "Description": "accelerometer FrontRight",
    "_id": "recO88KvsgFus5kFt"
  },
  {
    "slug": "mbh-23-ac",
    "setups": [
      "rec9msLpL4R9tceAO",
      "reccVDMc5e3jgFdBo"
    ],
    "status": "Inactive",
    "Description": "accelerometer BottomRight",
    "_id": "recn7bh9ms5dNWnLF"
  },
  {
    "slug": "n13-1-vib",
    "status": "Inactive",
    "_id": "recZRvrVge8mFTzYL"
  },
  {
    "slug": "n12-1-vib",
    "status": "Inactive",
    "_id": "recZoe18WEoHcbROw"
  },
  {
    "slug": "n11-1-vib",
    "status": "Inactive",
    "_id": "recVFcc1Ws92ZNztc"
  },
  {
    "slug": "n18-1-vib",
    "status": "Inactive",
    "_id": "recy0WiZj1FJDqpCH"
  },
  {
    "slug": "cold-path",
    "status": "Inactive",
    "_id": "recBQfDVNcqvjbcNv"
  },
  {
    "slug": "nyh-1-acc-stag",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "devices": [
      "receemHKTwt0AtSjH"
    ],
    "lenses": [
      "recb0iGXNF4ttgsqy"
    ],
    "status": "Active",
    "Description": "Peak Acceleration (g)",
    "lens_blocks": [
      "recubsyhm7Omz0p4J",
      "reccRij0Z9SAN0qcJ",
      "rectDb9oL5pPoeMUN"
    ],
    "app": "{\n  \"beat-name\": \"crazy0|crazy1|crazy2|crazy3\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "rec7SFE16NDahjr6j",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "reciz2H1wHyUuOqZ4"
  },
  {
    "slug": "nyh-1-bst-stag",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "devices": [
      "receemHKTwt0AtSjH"
    ],
    "lenses": [
      "recHe0EGSjf5E5Pdi"
    ],
    "status": "Active",
    "Description": "Peak Acceleration (g)",
    "lens_blocks": [
      "recubsyhm7Omz0p4J",
      "reccRij0Z9SAN0qcJ",
      "rectDb9oL5pPoeMUN"
    ],
    "app": "{\n  \"beat-name\": \"'goofy0'\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "rec7SFE16NDahjr6j",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "rec1wF7yIam4ttWgp"
  },
  {
    "slug": "nyh-1-bst",
    "setups": [
      "reccVDMc5e3jgFdBo",
      "recmSgo8BCqGZUwo3",
      "rec5cZpt1AQit2uXG"
    ],
    "devices": [
      "receemHKTwt0AtSjH"
    ],
    "lenses": [
      "recv1HUNAKeTgPjp7"
    ],
    "status": "Active",
    "Description": "Peak Acceleration (g)",
    "lens_blocks": [
      "recubsyhm7Omz0p4J",
      "reccRij0Z9SAN0qcJ",
      "rectDb9oL5pPoeMUN"
    ],
    "app": "{\n  \"beat-name\": \"'goofy1'\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "rec7SFE16NDahjr6j",
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recvPdRExK39JEKhZ"
  },
  {
    "slug": "lho-3-dust",
    "setups": [
      "recatUzBFGucIqKLk",
      "recAurjGO2jRXkwRI",
      "recxPootjl5yNhz5p",
      "reck0pK17FgXMs4Vz",
      "rechl18CctApcCtVQ",
      "recisokkw1Hb1lvTW",
      "recYjtLtvvOl4z0z4",
      "recBR7cXZ5bcHdL19",
      "reccVDMc5e3jgFdBo",
      "rec5cZpt1AQit2uXG",
      "recwui7P1WhFDcRJk",
      "recmSgo8BCqGZUwo3",
      "rec4UxwB1AMhRyGtu"
    ],
    "devices": [
      "recwVhY4xbaRopjQA"
    ],
    "lenses": [
      "recp4ROAAbvYxd7LI"
    ],
    "status": "Active",
    "Description": "Peak Air Pressure (Pa)",
    "lens_blocks": [
      "recscoYyaBJ9LnMLD",
      "recvaN9hrVcjoyPMj"
    ],
    "app": "{\n  \"beat-name\": \"Air fast\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "recGrH5a79W6fihEg",
      "recngNqbEkiYWt5Gz"
    ],
    "_id": "recioW4KWg4zPwOIF"
  },
  {
    "slug": "oft-1-tmp",
    "setups": [
      "recrlndJUpz6vsVeu",
      "recUty6BjCcM4jx4o",
      "reccVDMc5e3jgFdBo"
    ],
    "devices": [
      "recDI71hQ208NmbQs"
    ],
    "lenses": [
      "recX6OkxfrCqldLhP"
    ],
    "status": "Active",
    "Description": "Temperature Measurement",
    "app": "{\n  \"beat-name\": \"'groom0'\",\n  \"partitionKey\": \"[\\\"99\\\"]\"\n}",
    "configs": [
      "rec7SFE16NDahjr6j"
    ],
    "_id": "reckMmnVlOWlhB9hA"
  },
  {
    "slug": "tra-1-vib",
    "devices": [
      "rec3i9oiJmI5XEscc"
    ],
    "lenses": [
      "recBDu3XnWSufJpT7"
    ],
    "status": "Active",
    "Description": "Vibration Stats & Bursts",
    "lens_blocks": [
      "recsqwPNBcVcJdxTi"
    ],
    "app": "{\n  \"beat-name\": \"E1-sISEE-4\",\n  \"burst-name\": \"E1-bISEE-2\",\n  \"partitionKey\": \"[\\\"AECOM1\\\"]\",\n}\n",
    "_id": "recT76yaISvkZ9Flg"
  },
  {
    "slug": "tra-2-vib",
    "devices": [
      "reckSKXnODEo6zhge"
    ],
    "lenses": [
      "recJnlDm6hayL2X4o"
    ],
    "status": "Active",
    "Description": "Vibration Stats & Bursts",
    "lens_blocks": [
      "recUbeUmAGEY5JIX8",
      "recxlaz2bYhiqZ0KC"
    ],
    "app": "{\n  \"beat-name\": \"E2-sISEE-4\",\n  \"burst-name\": \"E2-bISEE-2\",\n  \"partitionKey\": \"[\\\"AECOM2\\\"]\",\n}",
    "_id": "rec2jaMt6cbdZFin4"
  },
  {
    "slug": "tra-1-bst",
    "devices": [
      "rec3i9oiJmI5XEscc"
    ],
    "lenses": [
      "recE6B2uUt8VKHjbu"
    ],
    "status": "Active",
    "Description": "Burst",
    "lens_blocks": [
      "rec5EgGkiVbt3xlwH"
    ],
    "app": "{\n  \"beat-name\": \"E1-bISEE-2\",\n  \"partitionKey\": \"[\\\"AECOM1\\\"]\",\n}\n\n",
    "configs": [
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recx9Ja6uStoIPEB8"
  },
  {
    "slug": "tra-2-bst",
    "lenses": [
      "recpphl6SiHo4lAny"
    ],
    "status": "Active",
    "Description": "Burst",
    "app": "{\n  \"beat-name\": \"E2-bISEE-2\",\n  \"partitionKey\": \"[\\\"AECOM2\\\"]\",\n}\n\n",
    "configs": [
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recwbGBG6A55hHHWo"
  },
  {
    "slug": "tra-2-vib",
    "devices": [
      "reckSKXnODEo6zhge"
    ],
    "_id": "recX9jA5VbNDBoBnT"
  },
  {
    "slug": "tra-4-vib",
    "devices": [
      "rec2Q39RV1h3B0CuH"
    ],
    "status": "Active",
    "Description": "Vibration",
    "app": "{\n  \"beat-name\": \"E4-sISEE-2\",\n  \"partitionKey\": \"[\\\"test\\\"]\",\n}\n\n",
    "configs": [
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recT0yG41SmBMMqnv"
  },
  {
    "slug": "tra-4-bst",
    "devices": [
      "rec2Q39RV1h3B0CuH"
    ],
    "status": "Active",
    "Description": "Burst",
    "app": "{\n  \"beat-name\": \"E4-bISEE-2\",\n  \"partitionKey\": \"[\\\"test\\\"]\",\n}\n\n",
    "configs": [
      "recOJKmNsxEYqQw5p"
    ],
    "_id": "recmvBwjdfqEcpkqh"
  }
]