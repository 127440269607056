import React from 'react'
import logo from './etc/recovib.png'

const RecovibLogo = props => {
  const { /*noLink,*/ height } = props
  return (
		<a href="https://micromega-dynamics.com/products/recovib/miniature-vibration-recorder/">
			<img src={`https://assets.recovib.io/shared/recovib.png`} height={height} alt="recovib logo" />
		</a>
	)
}

export default RecovibLogo
