export const table = [
  {
    "slug": "algo:tmd-efficiency",
    "tags": [
      "trigger:tmd"
    ],
    "i18n": "Dedicated algorithm for Tuned Mass Damper (TMD) efficiency computation.",
    "category": [
      "algo"
    ],
    "data-sources": [
      "recawV8HyIaUQ5NEJ"
    ],
    "data_stream copy": "wnl-3-tmd",
    "_id": "reclEmVTkbAZVg1Lw"
  },
  {
    "slug": "aq:monitor-4",
    "i18n": "Monitor-4",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "rec7lvV7qL8pFZMnX",
      "recCFmt0tbh80DQfe",
      "reck52xR8r2RfJwbt",
      "recQ376QP3DSjJwW9",
      "rec34grJUu0O5zSov",
      "receLHTYTEsMdL7hr",
      "recc1TqHR12GLM5z2",
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst, mbh-1-pot, mbh-12-tem, wnd-1-acc, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem",
    "_id": "recGLTiz5RGdL3Tc9"
  },
  {
    "slug": "aq:monitor-8",
    "i18n": "Monitor-8",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "recj2lS80a8DGR9hB",
      "recHwP0ILtAabECEi",
      "recawV8HyIaUQ5NEJ",
      "recYZJgbD3tzJmlix",
      "recmWXXG0xuwp70TR",
      "rectLCYTwsTaHkC5A",
      "recKn1WnMnQN0iHVL",
      "recRXv1BZvV00joAF",
      "recPbV9HJT2FKjndK",
      "recS9FMg5nuJMRTFQ",
      "rechvayE0wvGwSXmu",
      "rec7ZdOkSsxjBjo0K",
      "rec9ACuocNTdBtpVh",
      "recOL4qWlaN3S6u0S",
      "reciz2H1wHyUuOqZ4",
      "rec1wF7yIam4ttWgp",
      "recvPdRExK39JEKhZ",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-2-air, nyh-1-vib, nyh-21-acc, demo-2-air, ecv-1-vib, wnl-3-tmd, gsm-1-tem, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "rec5cZpt1AQit2uXG"
  },
  {
    "slug": "aq:monitor-8-i",
    "i18n": "Monitor-8-I",
    "category": [
      "acquisition"
    ],
    "_id": "rec1H95oqY7rHHcsT"
  },
  {
    "slug": "aq:recovib-blasting",
    "i18n": "Recovib.Blasting",
    "category": [
      "acquisition"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "rec3ecsrSjOrHzHm3",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recaFT8ptuaTfSNaP",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib",
    "_id": "recmyNahgfmPYnjKy"
  },
  {
    "slug": "cm:4g-board",
    "i18n": "4G Board",
    "category": [
      "comm"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "rec3ecsrSjOrHzHm3",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "rec7lvV7qL8pFZMnX",
      "recCFmt0tbh80DQfe",
      "reck52xR8r2RfJwbt",
      "recQ376QP3DSjJwW9",
      "rec34grJUu0O5zSov",
      "receLHTYTEsMdL7hr",
      "recYZJgbD3tzJmlix",
      "recmWXXG0xuwp70TR",
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e",
      "rectLCYTwsTaHkC5A",
      "recKn1WnMnQN0iHVL",
      "recRXv1BZvV00joAF",
      "recPbV9HJT2FKjndK",
      "recS9FMg5nuJMRTFQ",
      "rechvayE0wvGwSXmu",
      "rec7ZdOkSsxjBjo0K",
      "recaFT8ptuaTfSNaP",
      "recc1TqHR12GLM5z2",
      "rec9ACuocNTdBtpVh",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "reciz2H1wHyUuOqZ4",
      "rec1wF7yIam4ttWgp",
      "recvPdRExK39JEKhZ",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, nyh-1-vib, pom-1-vib, nyh-21-acc, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib, ecm-2-dst, mbh-1-pot, mbh-12-tem, gsm-1-tem, wnd-1-acc, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "recmSgo8BCqGZUwo3"
  },
  {
    "slug": "cm:wifi",
    "i18n": "Wifi",
    "category": [
      "comm"
    ],
    "data-sources": [
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recmYNTij4EbkpLMK"
  },
  {
    "slug": "conn:i1-i1-i1-i1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recCYcT1SEOBREETJ"
  },
  {
    "slug": "conn:i2-i2-i2-i2",
    "category": [
      "conn"
    ],
    "data-sources": [
      "rectLCYTwsTaHkC5A",
      "recKn1WnMnQN0iHVL",
      "recRXv1BZvV00joAF",
      "recPbV9HJT2FKjndK",
      "recS9FMg5nuJMRTFQ",
      "rechvayE0wvGwSXmu",
      "rec7ZdOkSsxjBjo0K"
    ],
    "data_stream copy": "pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc",
    "_id": "recB8BAts2FtXbYnm"
  },
  {
    "slug": "conn:i3",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recmWXXG0xuwp70TR"
    ],
    "data_stream copy": "thp-1-vib, thp-2-vib, gsm-1-tem",
    "_id": "recg6dYBy1bLV5L1X"
  },
  {
    "slug": "conn:i3-i1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recUzWxXqUFbDQxGh"
  },
  {
    "slug": "conn:i3-icp1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recwui7P1WhFDcRJk"
  },
  {
    "slug": "conn:ud3-u1",
    "category": [
      "conn"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "rechICH7tz6RmoEpr"
  },
  {
    "slug": "host:pr202",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "https://pr-202-01.azurewebsites.net/",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recHwP0ILtAabECEi",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, thp-1-vib, thp-2-vib, ecv-1-vib, wnl-3-tmd",
    "_id": "recgSCKxwVbEzKKaF"
  },
  {
    "slug": "host:recovib-cloud-deprecated",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "Recovib-monitoring cloud platform (deprecated)",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "Deprecated": true,
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "rechqMU6WOfaYO6MS"
  },
  {
    "slug": "host:recovib.io",
    "tags": [
      "ix:hosted-app"
    ],
    "i18n": "http://recovib.io",
    "category": [
      "deployment"
    ],
    "data-sources": [
      "recj2lS80a8DGR9hB",
      "recYZJgbD3tzJmlix",
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e",
      "recaFT8ptuaTfSNaP",
      "recKI0KlGZoDKSCMz",
      "recn7bh9ms5dNWnLF",
      "recO88KvsgFus5kFt",
      "rec9ACuocNTdBtpVh",
      "recOL4qWlaN3S6u0S",
      "reciz2H1wHyUuOqZ4",
      "rec1wF7yIam4ttWgp",
      "recvPdRExK39JEKhZ",
      "recioW4KWg4zPwOIF",
      "reckMmnVlOWlhB9hA"
    ],
    "data_stream copy": "lho-2-air, nyh-1-vib, pom-1-vib, mbh-24-ac, nyh-21-acc, demo-2-air, coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd, mbh-22-ac, mbh-23-ac",
    "_id": "reccVDMc5e3jgFdBo"
  },
  {
    "slug": "in:1-axis-cm-accelero",
    "tags": [
      "type:1-axis"
    ],
    "i18n": "1-axis CM accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "rec6hMXsNuwKMpEvG",
      "recrezFVIAeIXUWuN",
      "rec9Ncv41AXPiuoJ4",
      "recnkL8EuvfNToT6e"
    ],
    "data_stream copy": "coo-1-cnd, coo-2-cnd, coo-3-cnd, coo-4-cnd",
    "_id": "recyIkVIMQkSEBfhm"
  },
  {
    "slug": "in:1-axis-iac-i-accelero",
    "tags": [
      "type:1-axis"
    ],
    "i18n": "standard IAC-I 1-axis accelerometer",
    "category": [
      "sensor"
    ],
    "_id": "recavXFxK8kJ9q7E3"
  },
  {
    "slug": "in:2-axis-iac-i-accelero",
    "tags": [
      "type:2-axis"
    ],
    "i18n": "IAC-I 2-axis accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "rectLCYTwsTaHkC5A",
      "recKn1WnMnQN0iHVL",
      "recRXv1BZvV00joAF",
      "recPbV9HJT2FKjndK",
      "recS9FMg5nuJMRTFQ",
      "rechvayE0wvGwSXmu",
      "rec7ZdOkSsxjBjo0K",
      "recsFyD07uSK9FPuN",
      "recKI0KlGZoDKSCMz",
      "recn7bh9ms5dNWnLF",
      "recO88KvsgFus5kFt"
    ],
    "data_stream copy": "mbh-24-ac, pgd-1-acc, pgd-20-inc, pgd-2-acc, pgd-3-acc, pgd-21-inc, pgd-22-inc, pgd-4-acc, mbh-21-ac, mbh-22-ac, mbh-23-ac",
    "_id": "rec9msLpL4R9tceAO"
  },
  {
    "slug": "in:3-axis-hres-i-accelero",
    "tags": [
      "type:3-axis",
      "type:multi-series",
      "trigger:accelero"
    ],
    "i18n": "3-axis HiRes I accelerometer ",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "rec3ecsrSjOrHzHm3",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recWkL3V9LeY0PBo4",
      "recHwP0ILtAabECEi",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib, ecv-1-vib",
    "_id": "recKNVffgsPVAmwzg"
  },
  {
    "slug": "in:3-axis-hres-ud-accelero",
    "tags": [
      "type:3-axis",
      "type:multi-series",
      "trigger:accelero"
    ],
    "i18n": "3-axis HiRes Ud accelerometer",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "rec3ecsrSjOrHzHm3",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recaFT8ptuaTfSNaP",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib",
    "_id": "recaw1BDDe1wduoEt"
  },
  {
    "slug": "in:air-pressure-infrasound-mic-2-250hz",
    "tags": [
      "trigger:air-pressure"
    ],
    "i18n": "Air Pressure Microphone (infrasound from 2Hz to 250Hz).",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "recj2lS80a8DGR9hB",
      "recOL4qWlaN3S6u0S"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "recRqjvYlJAlgElwq"
  },
  {
    "slug": "in:dust-meter",
    "tags": [
      "trigger:dust"
    ],
    "i18n": "Casella dust meter with U/I external converter ",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "rectWJ2AI1vs4gy8J"
    ],
    "data_stream copy": "ecm-2-dst",
    "_id": "recjEmTDdzEpQdIes"
  },
  {
    "slug": "in:high-res-sns",
    "tags": [
      "trigger:sns"
    ],
    "i18n": "HiRes Structural Noise Sensor",
    "category": [
      "sensor"
    ],
    "_id": "recb4oGZdQ1XU7iCb"
  },
  {
    "slug": "in:inclinometer",
    "tags": [
      "trigger:slope"
    ],
    "i18n": "inclinometer (without temperature compensation). ",
    "category": [
      "sensor"
    ],
    "_id": "rec7U9YrUkrbXTYBE"
  },
  {
    "slug": "in:potentiometer-sensor",
    "tags": [
      "trigger:voltage"
    ],
    "i18n": "String Potentiometer",
    "category": [
      "sensor"
    ],
    "_id": "recwX8DO2rPzklxie"
  },
  {
    "slug": "in:temperature-sensor",
    "tags": [
      "trigger:temperature"
    ],
    "i18n": "Temperature Sensor",
    "category": [
      "sensor"
    ],
    "data-sources": [
      "reckMmnVlOWlhB9hA"
    ],
    "_id": "recrlndJUpz6vsVeu"
  },
  {
    "slug": "info:alert-alarm-email",
    "tags": [
      "ix:email",
      "type:alarm",
      "ix:alert"
    ],
    "i18n": "Alarm email when acceleration exceed a given level in order to stop work. ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "recHwP0ILtAabECEi",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "recisokkw1Hb1lvTW"
  },
  {
    "slug": "info:alert-alarm-sms",
    "tags": [
      "ix:sms",
      "type:alarm",
      "ix:alert"
    ],
    "i18n": "Alarm SMS when acceleration exceed a given level in order to stop work. ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "recHwP0ILtAabECEi",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "recYjtLtvvOl4z0z4"
  },
  {
    "slug": "info:alert-burst-datapoints",
    "tags": [
      "ix:alert",
      "type:burst-data"
    ],
    "i18n": "Periodic bursts for FFT computations ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, wnl-3-tmd",
    "_id": "recxPootjl5yNhz5p"
  },
  {
    "slug": "info:alert-warning-email",
    "tags": [
      "ix:email",
      "type:warning",
      "ix:alert"
    ],
    "i18n": "Warning email when acceleration exceed a given level in order to take actions ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, wnl-3-tmd",
    "_id": "reck0pK17FgXMs4Vz"
  },
  {
    "slug": "info:alert-warning-sms",
    "tags": [
      "ix:sms",
      "type:warning",
      "ix:alert"
    ],
    "i18n": "Warning SMS when acceleration exceed a given level in order to take actions ",
    "category": [
      "tlm-hook"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "recHwP0ILtAabECEi",
      "recawV8HyIaUQ5NEJ",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib, ecm-1-vib, ecm-2-dst, ecv-1-vib, wnl-3-tmd",
    "_id": "rechl18CctApcCtVQ"
  },
  {
    "slug": "pow:pv-power-supply",
    "i18n": " PV power supply to avoid crossing cables",
    "category": [
      "power"
    ],
    "data-sources": [
      "rec7lvV7qL8pFZMnX",
      "recCFmt0tbh80DQfe",
      "reck52xR8r2RfJwbt",
      "recQ376QP3DSjJwW9",
      "rec34grJUu0O5zSov",
      "receLHTYTEsMdL7hr"
    ],
    "data_stream copy": "mbh-1-pot, mbh-12-tem, mbh-2-pot, mbh-3-pot, mbh-14-tem, mbh-15-tem",
    "_id": "rec3sn2cAAMGrGXI7"
  },
  {
    "slug": "risk:building-damage",
    "i18n": "Building Damage",
    "category": [
      "risk"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recAurjGO2jRXkwRI"
  },
  {
    "slug": "risk:sensitive-equipment",
    "tags": [
      "config:sensitive"
    ],
    "i18n": "Sensitive Equipment",
    "category": [
      "risk"
    ],
    "data-sources": [
      "rec3ecsrSjOrHzHm3",
      "recHwP0ILtAabECEi"
    ],
    "data_stream copy": "bie-1-vib, ecv-1-vib",
    "_id": "recLW0jq4FFmDOzwD"
  },
  {
    "slug": "risk:work-of-art",
    "tags": [
      "config:sensitive"
    ],
    "i18n": "Work of Art",
    "category": [
      "risk"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "recFoe4IasbXeB5Om"
  },
  {
    "slug": "sd:air-pressure-35pa",
    "tags": [
      "threshold:35pa",
      "trigger:air-pressure"
    ],
    "i18n": "Air pressure threshold at 35Pa",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recj2lS80a8DGR9hB",
      "recOL4qWlaN3S6u0S"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "recrWi63kzpzfMGeP"
  },
  {
    "slug": "sd:dust-x",
    "tags": [
      "trigger:dust"
    ],
    "i18n": "Dust",
    "category": [
      "standard"
    ],
    "data-sources": [
      "rectWJ2AI1vs4gy8J",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "ecm-2-dst",
    "_id": "rec4UxwB1AMhRyGtu"
  },
  {
    "slug": "sd:potentiometer-level",
    "tags": [
      "trigger:voltage"
    ],
    "i18n": "Potentiometer Level",
    "category": [
      "standard"
    ],
    "data-sources": [
      "rec7lvV7qL8pFZMnX",
      "recCFmt0tbh80DQfe",
      "reck52xR8r2RfJwbt"
    ],
    "data_stream copy": "mbh-1-pot, mbh-2-pot, mbh-3-pot",
    "_id": "reclmn4XnaSfHWq3B"
  },
  {
    "slug": "sd:sns",
    "tags": [
      "trigger:sns"
    ],
    "i18n": "SNS",
    "category": [
      "standard"
    ],
    "_id": "rec6EEUl9sxpVMHYf"
  },
  {
    "slug": "sd:temperature-level",
    "tags": [
      "trigger:temperature"
    ],
    "i18n": "Temperature Level",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recQ376QP3DSjJwW9",
      "rec34grJUu0O5zSov",
      "receLHTYTEsMdL7hr",
      "reckMmnVlOWlhB9hA"
    ],
    "data_stream copy": "mbh-12-tem, mbh-14-tem, mbh-15-tem",
    "_id": "recUty6BjCcM4jx4o"
  },
  {
    "slug": "sd:tmd-efficiency",
    "tags": [
      "trigger:tmd"
    ],
    "i18n": "TMD efficiency",
    "category": [
      "standard"
    ],
    "_id": "recL76QQQ6IJtOL5Y"
  },
  {
    "slug": "sd:vib-din4150-3-L2",
    "tags": [
      "threshold:din4150",
      "trigger:vibration"
    ],
    "i18n": "Monitoring Building Vibration according to DIN4150-3/L2 (normal buildings)",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recq6RoQjskbkCgv8",
      "recaFT8ptuaTfSNaP",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, pom-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, thp-1-vib",
    "_id": "recGP5T2pI5c001gq"
  },
  {
    "slug": "sd:vib-din4150-3-L3",
    "tags": [
      "threshold:din4150",
      "trigger:vibration"
    ],
    "i18n": "Monitoring Building Vibration according to DIN4150-3/L3 (sensitive buildings).",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recGkdxPB7IAVK7SS",
      "recWkL3V9LeY0PBo4",
      "recHwP0ILtAabECEi"
    ],
    "data_stream copy": "thp-2-vib, ecm-1-vib, ecv-1-vib",
    "_id": "rec1PnR9H0tyl1V2Z"
  },
  {
    "slug": "sd:vib-level-monitoring",
    "tags": [
      "trigger:vibration"
    ],
    "i18n": "Monitoring Vibration thresholds for Warning and Alarms (actions vs stop work)",
    "category": [
      "standard"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "rec3ecsrSjOrHzHm3",
      "recq6RoQjskbkCgv8",
      "recGkdxPB7IAVK7SS",
      "recWkL3V9LeY0PBo4",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib, bie-1-vib, thp-1-vib, thp-2-vib, ecm-1-vib",
    "_id": "recoHXo6481igyAih"
  },
  {
    "slug": "site:construction-work",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Construction Work ",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J",
      "recHwP0ILtAabECEi"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst, ecv-1-vib",
    "_id": "recboIXzIXpJCcVeu"
  },
  {
    "slug": "site:hospital",
    "tags": [
      "where:indoors"
    ],
    "i18n": "Hospital",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recHwP0ILtAabECEi"
    ],
    "data_stream copy": "ecv-1-vib",
    "_id": "recnxYlASdFJhbEXn"
  },
  {
    "slug": "site:museum",
    "tags": [
      "where:indoors"
    ],
    "i18n": "Museum",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recWkL3V9LeY0PBo4",
      "rectWJ2AI1vs4gy8J"
    ],
    "data_stream copy": "ecm-1-vib, ecm-2-dst",
    "_id": "rec5HXkS10mC1Cuic"
  },
  {
    "slug": "site:quarry",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Quarry",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recBR7cXZ5bcHdL19"
  },
  {
    "slug": "site:rails",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Rails",
    "category": [
      "environnement"
    ],
    "_id": "rec3YawO7JtOcfuJr"
  },
  {
    "slug": "site:recreation-park",
    "tags": [
      "where:outdoors"
    ],
    "i18n": "Recreation Park",
    "category": [
      "environnement"
    ],
    "data-sources": [
      "recawV8HyIaUQ5NEJ"
    ],
    "data_stream copy": "wnl-3-tmd",
    "_id": "rechDSmu9ljpMnaVg"
  },
  {
    "slug": "trigger:quarry-blasting",
    "i18n": "Quarry Blasting",
    "category": [
      "trigger"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "recj2lS80a8DGR9hB",
      "reckUcHRJywW08ZsE",
      "recOL4qWlaN3S6u0S",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk",
      "recioW4KWg4zPwOIF"
    ],
    "data_stream copy": "lho-1-vib, lho-2-air, demo-1-vib, demo-2-air, cem-1-vib, vmv-1-vib",
    "_id": "recatUzBFGucIqKLk"
  },
  {
    "slug": "unit:mm/s",
    "tags": [
      "type:time-series"
    ],
    "i18n": "Millimeters per second",
    "category": [
      "tlm-unit"
    ],
    "data-sources": [
      "recIBDky5zeo675eG",
      "reckUcHRJywW08ZsE",
      "recR2THdoCs07Sdpg",
      "recBwNAToeCXDLxJk"
    ],
    "data_stream copy": "lho-1-vib, demo-1-vib, cem-1-vib, vmv-1-vib",
    "_id": "recbNHAVopQYizcp4"
  },
  {
    "slug": "unit:Pa",
    "tags": [
      "type:time-series"
    ],
    "i18n": "Pascal",
    "category": [
      "tlm-unit"
    ],
    "data-sources": [
      "recj2lS80a8DGR9hB",
      "recOL4qWlaN3S6u0S"
    ],
    "data_stream copy": "lho-2-air, demo-2-air",
    "_id": "rec0jxT6BUsYQbVKW"
  },
  {
    "slug": "monitor:structural",
    "i18n": "Structural Monitoring",
    "category": [
      "domain"
    ],
    "_id": "recYE1pqxtuTMOduh"
  },
  {
    "slug": "tlm:1-per-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "periodic",
    "category": [
      "tlm-message-type"
    ],
    "_id": "recOLi4JOdjYkwvvW"
  },
  {
    "slug": "tlm:2-thr-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "threshold",
    "category": [
      "tlm-message-type"
    ],
    "_id": "recWXm6wqH9V5958t"
  },
  {
    "slug": "tlm:3-alt-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "alert",
    "category": [
      "tlm-message-type"
    ],
    "_id": "recqXWFmnEwYTXj7J"
  },
  {
    "slug": "tlm:4-sta-v1",
    "tags": [
      "tlm:packet"
    ],
    "i18n": "stats",
    "category": [
      "tlm-message-type"
    ],
    "_id": "rec2jBDpfbLFgQLuQ"
  },
  {
    "slug": "ingest:vib",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recCh216QWF62SsVr"
  },
  {
    "slug": "ingest:retroVib",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recVft4qaSVktv2ch"
  },
  {
    "slug": "ingest:air",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "recwMSmELOnoFz0rW"
  },
  {
    "slug": "ingest:coldpath",
    "tags": [
      "tlm:packet"
    ],
    "category": [
      "tlm-ingest-format"
    ],
    "_id": "reckHr2YFJpLOe17x"
  }
]